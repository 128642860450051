import each from 'lodash/each';
import { setValidator } from 'poronline-shared-vue-components/services/validators';

import * as filesValidator from './services/validators/files';
import * as ucrnValidators from './services/validators/ucrn';
import * as berthValidators from './services/validators/berth';

// add custom validators
each({ ...filesValidator, ...ucrnValidators, ...berthValidators }, (validator, name) => {
  setValidator(name, validator);
});

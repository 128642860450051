import get from 'lodash/get';
import Vue from 'vue';
import Router from 'vue-router';
import store from './store';

const Login = () => import(/* webpackChunkName: "login-page" */ './views/login-page');
const NoAgency = () => import(/* webpackChunkName: "no-agency-page" */ './views/no-agency-page');
const Home = () => import(/* webpackChunkName: "home-page" */ './views/home-page');
const ErrorPage = () => import(/* webpackChunkName: "error-page" */ './views/error-page');
const VisitsPage = () => import(/* webpackChunkName: "visits-page" */ './views/visits/visits-page');
const MyRequests = () => import(/* webpackChunkName: "requests-page" */ './views/requests/requests-page');
const NewRequestPage = () => import(/* webpackChunkName: "new-request" */ './views/requests/new-request');
const OutsideThePet = () =>
  import(/* webpackChunkName: "outside-the-pet-page" */ './forms/outside-the-pet/outside-the-pet-page');
const RequestRepair = () =>
  import(/* webpackChunkName: "request-repair-page" */ './forms/request-repair/request-repair-page');
const Transport = () => import(/* webpackChunkName: "transport-page" */ './forms/transport/transport-page');
const OudeMaas = () => import(/* webpackChunkName: "oude-maas-page" */ './forms/oude-maas/oude-maas-page');

Vue.use(Router);
const ChildRouter = Vue.extend({
  render: (h) => h('router-view'),
});

const routes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Home,
  },
  {
    path: '/visits/:ucrn([A-Z]{3,6}[0-9]{8})?',
    name: 'visits',
    component: VisitsPage,
    props: true,
  },
  {
    path: '/visits/:ucrn([A-Z]{3,6}[0-9]{8})?/request/:requestType([a-z]+)?/:id(\\d+)?',
    name: 'visitRequest',
    component: VisitsPage,
    props: true,
  },
  {
    path: '/requests',
    component: ChildRouter,
    children: [
      {
        path: 'new',
        component: ChildRouter,
        children: [
          {
            path: '',
            name: 'new-request',
            component: NewRequestPage,
          },
          {
            path: 'outside-the-pet',
            name: 'new-request-mooring',
            component: OutsideThePet,
            props: true,
          },
          {
            path: 'request-repair',
            name: 'new-request-repair',
            component: RequestRepair,
            props: true,
          },
          {
            path: 'transport',
            name: 'new-request-transport',
            component: Transport,
            props: true,
          },
          {
            path: 'oude-maas',
            name: 'new-request-oudemaas',
            component: OudeMaas,
            props: true,
            beforeEnter(to, from, next) {
              if (!store.getters.FEATURE_LOADED) {
                setTimeout(next, 0, to);
                return;
              }

              if (!store.getters.FEATURE_OUDE_MAAS) {
                next({ name: 'dashboard' });
                return;
              }

              next();
            },
          },
          {
            path: '*',
            redirect: '/requests/new',
          },
        ],
      },
      {
        path: ':requestType([a-z]+)?/:id(\\d+)?',
        name: 'requests',
        component: MyRequests,
        props: true,
      },
      {
        path: '*',
        redirect: '/requests',
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      noAuthorizationNeeded: true,
    },
    beforeEnter(to, from, next) {
      if (store.state.auth.isAuthenticated) {
        next(get(to, 'query.redirect', '/'));
        return;
      }
      next();
    },
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      store.dispatch('LOGOUT').then(() => {
        next({ name: 'login' });
      });
    },
  },
  {
    path: '/no-agency',
    name: 'noAgency',
    component: NoAgency,
    beforeEnter(to, from, next) {
      const profile = store.getters.USER_PROFILE;
      if (profile && profile.organisation) {
        next('/');
      }
      next();
    },
    meta: {
      noAgencyNeeded: true,
    },
  },
  {
    path: '/system-error',
    name: 'systemError',
    component: ErrorPage,
    meta: {
      noAuthorizationNeeded: true,
    },
    beforeEnter(to, from, next) {
      store
        .dispatch('CHECK_IS_AUTHENTICATED')
        .then(() => {
          next({ name: 'dashboard' });
        })
        .catch(() => {
          next();
        });
    },
  },
  {
    path: '*',
    redirect: '/dashboard',
  },
];

const router = new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  linkActiveClass: 'active',
  linkExactActiveClass: 'active-exact',
  routes,
});

router.beforeEach((to, from, next) => {
  if ('systemError' === to.name) {
    next();
    return;
  }

  // ask the backend if we are logged in if we don't know that
  if (null === store.state.auth.isAuthenticated) {
    store
      .dispatch('CHECK_IS_AUTHENTICATED')
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'systemError' });
      });
    return;
  }

  next();
});

router.beforeEach((to, from, next) => {
  if (to.meta.noAuthorizationNeeded) {
    next();
    return;
  }

  // redirect to login when not logged in
  if (!store.state.auth.isAuthenticated) {
    next({ name: 'login', query: { redirect: to.path } });
    return;
  }

  const profile = store.getters.USER_PROFILE || {};
  if (!to.meta.noAgencyNeeded && !profile.organisation) {
    next({ name: 'noAgency' });
    return;
  }

  next();
});

export default router;

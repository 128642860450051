export default {
  getters: {
    COMPANY_NAME: (state, { USER_PROFILE: profile }) => {
      return (profile || {}).organisation;
    },
  },
  actions: {
    async ON_AUTHENTICATED({ commit, dispatch, getters }) {
      commit('CLEAR_VISITS');

      await dispatch('LOAD_USER_PROFILE');
      const profile = getters.USER_PROFILE;
      if (!profile || !getters.USER_PROFILE.organisation) {
        return;
      }

      dispatch('LOAD_FEATURES');
      dispatch('LOAD_REQUESTS');
      dispatch('LOAD_VISITS');
    },
  },
};

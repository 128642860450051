import mapKeys from 'lodash/mapKeys';
import kebabCase from 'lodash/kebabCase';
import sharedNlNl from 'poronline-shared-vue-components/locales/nl-nl';
import sharedEnGb from 'poronline-shared-vue-components/locales/en-gb';
import nlNl from './nl-nl';
import enGb from './en-gb';

const defaultedNlNl = { ...sharedNlNl, ...nlNl };
const defaultedEnGb = { ...sharedEnGb, ...enGb };

const localizationResources = mapKeys({ nlNl: defaultedNlNl, enGb: defaultedEnGb }, (value, key) => kebabCase(key));
export const localeOptions = Object.keys(localizationResources);
export default localizationResources;

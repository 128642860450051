<template>
  <div id="app" :loading="loading">
    <router-view />
    <top-notifications data-test="alerts" />
    <modal-list />
  </div>
</template>

<script>
import ModalList from 'poronline-shared-vue-components/components/modals/modal-list';
import TopNotifications from 'poronline-shared-vue-components/components/top-notification';
import withProgressBar from 'poronline-shared-vue-components/components/with-progress-bar';

export default {
  name: 'App',
  mixins: [withProgressBar],
  components: { ModalList, TopNotifications },
};
</script>

import Vue from 'vue';
import globalMethods from 'poronline-shared-vue-components/services/global-methods';
import App from './app.vue';

import router from './router';
import store from './store';

import './validators';
import './scrollto';
import './ripple';
import './markdown';
import './flatpickr';
import './notification';
import i18n from './i18n';
import * as UserSnap from './usersnap';
import { addListener as addHttpListener } from './services/http';
import authListener from './services/api/auth-listener';
import progressListener from './services/api/progress-listener';
import errorListener from './services/error-listener';

import './styles/bootstrap.scss';
import './styles/poronline.scss';
import './styles/main.scss';

store.dispatch('INITIALIZE_TIME', i18n.locale);

Vue.use(globalMethods);

addHttpListener(progressListener);
addHttpListener(authListener);
addHttpListener(errorListener);

Vue.use(UserSnap, {
  consoleRecorder: true,
  consoleRecorderCfg: {
    maxMessages: 500, // default 50
    maxExceptions: 500, // default 50
    logObjectSize: 20480, // default 2048 bytes
  },
});

Vue.config.productionTip = false;
Vue.config.errorHandler = function (err, vm, info) {
  throw err;
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

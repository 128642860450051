import * as http from '../http';

/* Request overview */
export const getRequestsList = () => http.get('/api/hamis/forms/status-overview');

/* Request detail by ID */
export const getOutsideThePetRequestDetails = (id) =>
  http.get(`/api/hamis/forms/permission-outside-pet/${id}`).then(splitAttachments);
export const getRequestRepairRequestDetails = (id) =>
  http.get(`/api/hamis/forms/request-repair/${id}`).then(splitAttachments);
export const getGenericRequestDetails = (id) =>
  http.get(`/api/hamis/forms/generic-applications/${id}`).then(mapAttachmentsToOldFormat).then(splitAttachments);
export const getGenericRequestContent = (id) => http.get(`/api/hamis/forms/generic-applications/${id}/content`);

/* Submit Request */
export const postRequestOutsideThePet = (data) => http.post('/api/hamis/forms/permission-outside-pet', data);
export const postRequestRepair = (data) => http.post('/api/hamis/forms/request-repair', data);
export const postRequestGenericApplication = (data) => http.post('/api/hamis/forms/generic-applications', data);

// The attachments from the new generic applications (currently only used for transport applications)
// return the attachments in a different format but some components need to be able to work with both old and new.
// This function maps the attachments from the new generic application details response to the old format so that
// components consuming this don't need to if/else or left/null-coalesce the attachments
const mapAttachmentsToOldFormat = ({ data, ...request }) => {
  const { attachments, ...rest } = data;

  return {
    ...request,
    data: {
      ...rest,
      attachments: (attachments ?? []).map(({ hash, contentType, ...attachment }) => ({
        externalId: hash,
        mimeType: contentType,
        ...attachment,
      })),
    },
  };
};

/**
 * Split attachments into buckets of 'uploadedBy'
 *
 * @param {Object} response received from api
 * @returns {Object} mutated data with extra field, field is null otherwise
 */
const splitAttachments = ({ data }) => {
  const attachments = data.attachments.reduce((buckets, attachment) => {
    const bucket = attachment.uploadedBy;
    if (!Object.keys(buckets).includes(bucket)) {
      Object.assign(buckets, {
        [bucket]: [],
      });
    }

    buckets[bucket].push(attachment);

    return buckets;
  }, {});

  return {
    data: {
      ...data,
      attachments,
    },
  };
};

export const install = (Vue, opts) => {
  const globalId = '09e17cc7-1701-4bea-859e-a4c6d5fef90d';
  const id = clientIdMap[window.location.hostname] ?? undefined;
  if (!id) return;

  window.onUserSnapLoad = onLoad.bind(undefined, opts, id);

  const doc = document;
  const script = doc.createElement('script');

  script.type = 'text/javascript';
  script.src = `//widget.usersnap.com/global/load/${globalId}?onload=onUserSnapLoad`;
  script.async = true;

  doc.head.appendChild(script);
};

const onLoad = function (opts, id, api) {
  api.init(opts);
  api.show(id);
};

const clientIdMap = {
  'my.portofrotterdam.com': '504e30b1-86e1-48f7-b311-f160f63cebce',
  'accp-thzbckq-2qthwgqmozlhm.eu-4.platformsh.site': 'a854f447-383c-4c83-aea4-226d27ba25fa',
};

import { requiredExpression } from 'poronline-shared-vue-components/services/validators';

const echo = (v) => v;

export const notMoerdijk = ({ visit, messageTranslationKey = 'form.berth.notMoerdijk', ...messageInterpolation }) => {
  const isValid = ({ value }) => {
    if (!requiredExpression(value)) {
      return true;
    }

    const berthVisit = visit.berthVisits.find((berthVisit) => berthVisit.externalId === value);

    return !berthVisit.berth.name.startsWith('MOERD ');
  };

  const errorMessage = ({ translate = echo }) =>
    translate(messageTranslationKey, { email: 'havendienst@portofmoerdijk.nl', ...messageInterpolation });

  return (options = {}) => isValid(options) || errorMessage(options);
};

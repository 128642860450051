export const createApplicationHttpPostBody = (type, model, version) => {
  const { ucrn, berthVisitId, movementCommId, attachments, ...content } = model;

  return {
    type,
    version,
    ucrn,
    berthVisitId,
    movementCommId,
    content,
    // fields from 'new' attachments are called different so we map the old field to new ones just before POST-ing
    attachments: (attachments ?? []).map(({ externalId: hash, fileName, mimeType: contentType, uploadedBy }) => ({
      hash,
      fileName,
      contentType,
      uploadedBy,
    })),
  };
};

import { getAuthCurrentState, getUserProfile, logout } from '../../services/api/openid';

export default {
  state: {
    isAuthenticated: null,
    userProfile: null,
  },
  getters: {
    IS_AUTHENTICATED: (state) => Boolean(state.isAuthenticated),
    USER_PROFILE: (state) => Object.freeze(state.userProfile),
  },
  mutations: {
    SET_AUTHENTICATED: (state, authenticated) => {
      state.isAuthenticated = authenticated;
    },
    SET_USER_PROFILE: (state, profile) => {
      state.userProfile = profile;
    },
  },
  actions: {
    CLEAR_SESSION_STATE({ commit }) {
      commit('SET_AUTHENTICATED', false);
      commit('SET_USER_PROFILE', null);
    },
    async CHECK_IS_AUTHENTICATED({ commit, dispatch }) {
      const { data } = await getAuthCurrentState();
      const isAuthorized = Boolean(data);

      commit('SET_AUTHENTICATED', isAuthorized);
      if (isAuthorized) {
        await dispatch('ON_AUTHENTICATED');
      }

      return isAuthorized;
    },
    async LOAD_USER_PROFILE({ commit }) {
      const { data } = await getUserProfile();

      commit('SET_USER_PROFILE', data);
      commit('SET_TIME_ZONE', data.timeZone ?? process.env.VUE_APP_HARBOUR_TIME_ZONE ?? 'Europe/Amsterdam');

      return data;
    },
    async LOGOUT({ dispatch }) {
      await logout();
      await dispatch('CLEAR_SESSION_STATE');
    },
  },
};

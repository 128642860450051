import Vue from 'vue';
import MarkdownIt from 'markdown-it';
import externalLinksPlugin from 'poronline-shared-vue-components/services/markdown-external-link-plugin';

const md = new MarkdownIt('commonmark', {
  linkify: true,
  breaks: true,
});

md.use(externalLinksPlugin, {
  internalDomains: [document.location.host],
  externalClassName: 'external-link',
  externalTarget: '_blank',
});

Vue.directive('markdown', (el, { value, modifiers }) => {
  el.innerHTML = md[modifiers.inline ? 'renderInline' : 'render'](value);
});

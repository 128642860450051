import Vue from 'vue';
import i18n from '../i18n';

export default (promise) => {
  promise.then(
    (res) => {
      if (res.status < 500) {
        return;
      }

      Vue.notify({
        type: 'danger',
        title: i18n.t('error.5xx.title'),
        text: i18n.t('error.5xx.general'),
        duration: -1,
      });
    },
    (err) => {
      Vue.notify({
        type: 'danger',
        title: i18n.t('error.5xx.title'),
        text: i18n.t('error.5xx.network'),
      });
    }
  );
};

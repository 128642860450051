import store from '../../store';
import router from '../../router';
import i18n from '../../i18n';
import Vue from 'vue';

export default (promise) => {
  promise.then(async (res) => {
    switch (res.status) {
      case 401:
        await store.dispatch('CLEAR_SESSION_STATE');
        await router.push({ path: '/login', query: { redirect: router.currentRoute.path } });
        break;

      case 403:
        Vue.notify({
          type: 'danger',
          title: i18n.t('error.403.title'),
          text: i18n.t('error.403.text'),
        });
        break;
    }
  });
};

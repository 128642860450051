import Vue from 'vue';
import reduce from 'lodash/reduce';

import { getVisit, getVisitShip, getVisitsList } from '../../services/api/visits';
import i18n from '../../i18n';

export default {
  state: {
    visitsLoading: false,
    selectedVisitDetails: null,
    visitsListLoading: false,
    visitsList: null,
    visits: {},
    ships: {},
    failed: {
      notFound: [],
      unassigned: [],
      gone: [],
    },
  },
  getters: {
    VESSELS_BY_IMO: (state) => state.ships,
    VISITS_DETAILS_BY_UCRN: (state) => state.visits,
    VISITS_LIST_BY_UCRN: (state) =>
      reduce(state.visitsList, (result, visit) => ({ ...result, [visit.ucrn]: visit }), {}),
  },
  mutations: {
    SET_VISITS_LIST_LOADING: (state, value) => {
      state.visitsListLoading = !!value;
    },
    SET_ACTIVE_VISITS: (state, visitsList) => {
      state.visitsList = visitsList;
      state.visitsListLoading = false;
    },
    SET_SELECTED_VISIT_DETAILS: (state, selectedVisitDetails) => {
      state.selectedVisitDetails = selectedVisitDetails;
    },
    SET_VISITS_LOADING: (state, value) => {
      state.visitsLoading = !!value;
    },
    SET_VISIT: (state, visit) => {
      state.visits = {
        ...state.visits,
        [visit.ucrn]: visit,
      };
    },
    SET_NO_VISIT: (state, { ucrn, reason }) => {
      if (!Object.keys(state.failed).includes(reason)) {
        return;
      }

      state.failed = {
        ...state.failed,
        [reason]: state.failed[reason].concat(ucrn),
      };
    },
    CLEAR_VISITS: (state) => {
      state.visits = {};
    },
    SET_SHIP: (state, ship) => {
      state.ships = {
        ...state.ships,
        [ship.shipNumber]: ship,
      };
    },
    SET_NO_SHIP: (state, imo) => {
      state.ships = {
        ...state.ships,
        [imo]: null,
      };
    },
  },
  actions: {
    async LOAD_VISITS({ commit }) {
      commit('SET_VISITS_LIST_LOADING', true);

      try {
        const { data } = await getVisitsList();
        commit('SET_ACTIVE_VISITS', data);
      } catch (e) {
        Vue.notify({
          type: 'warning',
          title: i18n.t('error.LOAD_VISITS.title'),
          text: i18n.t('error.LOAD_VISITS.text'),
        });

        commit('SET_VISITS_LIST_LOADING', false);
      }
    },
    async LOAD_VISIT_DETAILS({ commit, dispatch }, ucrn) {
      const visit = await dispatch('LOAD_VISIT', ucrn);

      if (!visit.failure) {
        await dispatch('LOAD_SHIP', { ucrn, imo: visit.vessel.imo });
        commit('SET_SELECTED_VISIT_DETAILS', visit);
      }
    },
    async LOAD_VISIT({ commit, state }, ucrn) {
      if (state.visits[ucrn]) {
        return state.visits[ucrn];
      }

      const [reason] = Object.entries(state.failed).find(([, list]) => list.includes(ucrn)) ?? [];
      if (reason) {
        return { failure: reason };
      }

      try {
        const { data } = await getVisit(ucrn);
        commit('SET_VISIT', data);
        return data;
      } catch (e) {
        let reason;
        const { response } = e;
        switch (response.status) {
          case 403:
            reason = 'forbidden';
            break;

          case 404:
            reason = /not found for [A-Z\s]+/.test(response.data?.message) ? 'unassigned' : 'notFound';
            break;

          case 410:
            reason = 'gone';
            break;

          default:
            Vue.notify({
              type: 'warning',
              title: i18n.t('error.LOAD_VISIT.title'),
              text: i18n.t('error.LOAD_VISIT.text', { ucrn }),
            });

            throw e;
        }

        commit('SET_NO_VISIT', { ucrn, reason });

        return { failure: reason };
      }
    },
    async LOAD_SHIP({ commit, state }, { ucrn, imo, silent = false }) {
      if (state.ships[imo] || null === state.ships[imo]) {
        return state.ships[imo];
      }

      try {
        const { data } = await getVisitShip(ucrn);
        commit('SET_SHIP', data);
        return data;
      } catch (e) {
        commit('SET_NO_SHIP', imo);

        !silent &&
          Vue.notify({
            type: 'warning',
            title: i18n.t('error.LOAD_SHIP.title'),
            text: i18n.t('error.LOAD_SHIP.text', { imo }),
          });
      }
    },
  },
};

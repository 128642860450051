import time from 'poronline-shared-vue-components/store/time';
import modals from 'poronline-shared-vue-components/store/modals';
import progress from 'poronline-shared-vue-components/store/progress';
import main from './modules/main';
import auth from './modules/auth';
import features from './modules/features';
import form from './modules/form';
import visits from './modules/visits';
import notifications from './modules/notifications';

export default {
  // Don't enable strict for production
  // see: https://vuex.vuejs.org/guide/strict.html
  strict: 'development' === process.env.NODE_ENV,
  modules: { main, auth, time, form, features, modals, progress, visits, notifications },
};

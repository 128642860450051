import { requiredExpression } from 'poronline-shared-vue-components/services/validators';
import prettyBytes from 'poronline-shared-vue-components/services/pretty-bytes';

export const defaultFileExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];

const KB = 1024;
const MB = KB * 1024;
export const defaultFileSize = 10 * MB;

const echo = (v) => v;

export const files = ({ maxSize = defaultFileSize, extensions = defaultFileExtensions, maxFiles = 0 } = {}) => {
  const extRegexp = extensions && new RegExp('\\.(' + extensions.join('|').replace(/\./g, '\\.') + ')$', 'i');

  const errorMessage = ({ value, translate = echo }) => {
    const messages = [];

    if (value.find((file) => file.size > maxSize)) {
      messages.push(translate('form.input.maxSize', { maxSize: prettyBytes(maxSize) }));
    }
    if (extensions && value.find((file) => -1 === file.fileName.search(extRegexp))) {
      messages.push(
        translate('form.input.extension', {
          extensions: extensions.join(', '),
        })
      );
    }
    if (maxFiles > 0 && value.length > maxFiles) {
      messages.push(translate('form.input.maxFiles', { maxFiles }));
    }
    return messages.join(' ');
  };

  const isValid = ({ value }) => {
    if (!requiredExpression(value)) {
      return true;
    }
    if (value.find((file) => file.size > maxSize)) {
      return false;
    }
    if (extensions && value.find((file) => -1 === file.fileName.search(extRegexp))) {
      return false;
    }
    if (maxFiles > 0 && value.length > maxFiles) {
      return false;
    }
    if (value.find((file) => file.error)) {
      return false;
    }
    return true;
  };

  return (options = {}) => isValid(options) || errorMessage(options);
};

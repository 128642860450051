import { getFeatures } from '../../services/api/features';

export default {
  state: {
    loaded: false,
    features: {},
  },

  getters: {
    FEATURE_LOADED: (state) => state.loaded,
    FEATURE_LOCALE_SWITCH: (state) => Boolean(state.features['locale-switch']),
    FEATURE_OUDE_MAAS: (state) => Boolean(state.features['oude-maas']),
  },

  mutations: {
    SET_FEATURES(state, features) {
      state.features = features.reduce((acc, { name, enabled }) => Object.assign(acc, { [name]: enabled }), {});
      state.loaded = true;
    },
  },

  actions: {
    async LOAD_FEATURES({ commit }) {
      const {
        data: { features },
      } = await getFeatures();

      commit('SET_FEATURES', features);
    },
  },
};

const METHOD_GET = 'GET';
const METHOD_POST = 'POST';
const METHOD_PATCH = 'PATCH';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';

const listeners = [];

export const addListener = (fn) => listeners.push(fn);

export const get = (uri, params = {}, headers = {}) => {
  const query = Object.keys(params).length > 0 ? new URLSearchParams(params) : '';
  const separator = query ? '?' : '';

  return request(uri + separator + query, METHOD_GET, null, headers);
};

export const post = (uri, data = {}, headers = {}, onProgress) => {
  const body = Object.keys(data).length > 0 ? JSON.stringify(data) : data;

  return request(uri, METHOD_POST, body, headers, onProgress);
};

export const put = (uri, data = {}, headers = {}) => {
  const body = Object.keys(data).length > 0 ? JSON.stringify(data) : null;

  return request(uri, METHOD_PUT, body, headers);
};

export const patch = (uri, data = {}, headers = {}) => {
  const body = Object.keys(data).length > 0 ? JSON.stringify(data) : null;

  return request(uri, METHOD_PATCH, body, headers);
};

export const del = (uri, headers = {}) => {
  return request(uri, METHOD_DELETE, null, headers);
};

const request = async (uri, method, body = null, headers = {}, onProgress) => {
  const isUpload =
    body instanceof FormData &&
    Array.from(body.values()).some((formDataEntryValue) => formDataEntryValue instanceof File);

  // keep a reference to the original fetch promise for the listeners
  const promise = isUpload
    ? // use ponyfill for uploads,
      futch(
        uri,
        {
          body,
          method: METHOD_POST,
          headers: {
            Accept: 'application/json',
            ...headers,
          },
        },
        onProgress
      )
    : // normal fetch otherwise
      fetch(uri, {
        method,
        body: body ?? undefined,
        mode: 'same-origin',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          ...(body ? { 'Content-Type': 'application/json' } : {}),
          ...headers,
        },
      });

  let res;
  let data = null;
  try {
    // await the result of the original fetch promise
    res = await promise;

    // let listeners do their thing
    listeners.forEach((fn) => fn(promise));

    // we really only use JSON so we just assume that and try to parse the response as such
    data = await res.json();
  } catch (err) {
    // let listeners do their thing
    listeners.forEach((fn) => fn(promise));

    // re-throw the error so the promise chain still rejects
    throw err;
  }

  // populate default response
  const response = {
    data,
    status: res.status,
    statusText: res.statusText,
    headers: res.headers,
  };

  // if the response was not in the 2xx range
  if (!res.ok) {
    // throw an error so the promise chains reject which seems like the semantically correct thing to do
    // stick the response object to the error so consumers down in the chain can introspect it
    const err = new Error(res.statusText);
    err.response = response;

    throw err;
  }

  // all fine so return the response
  return response;
};

/**
 * Ponyfill for a fetch-like interface that supports upload progress by wrapping XHR in a Promise
 *
 * Deliberately called fetch-_like_ since it's a reasonable effort to mimic the interface but doesn't attempt to be spec compliant.
 */
const futch = (uri, opts = {}, onProgress) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open(opts.method ?? METHOD_GET, uri);

    xhr.withCredentials = true;
    xhr.onerror = reject;
    xhr.onload = (evt) => {
      const { status, statusText, responseType, response } = evt.target;
      const body = 'json' === responseType ? JSON.stringify(response) : response;
      const headers = Object.fromEntries(
        evt.target
          .getAllResponseHeaders()
          .split('\r\n')
          .filter(Boolean)
          .map((line) => line.split(': '))
      );

      const res = new Response(body, {
        status,
        statusText,
        headers,
      });

      resolve(res);
    };

    for (let name in opts.headers ?? {}) {
      if (!opts.headers.hasOwnProperty(name)) {
        continue;
      }

      const value = opts.headers[name];

      xhr.setRequestHeader(name, value);

      if ('accept' === name.toLowerCase() && 'application/json' === value) {
        xhr.responseType = 'json';
      }
    }

    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress;
    }

    xhr.send(opts.body);
  });

import { requiredExpression, getValidator } from 'poronline-shared-vue-components/services/validators';
import store from '../../store';
import { getVisit } from '../api/visits.js';

const ucrn = getValidator('ucrn')();
const echo = (v) => v;

export const myUcrn = ({ vesselCategory = null }) => {
  const isValid = async ({ value, translate = echo }) => {
    if (!requiredExpression(value)) {
      return true;
    }

    const ret = ucrn({ value });

    if (ret !== true) {
      return translate(ret);
    }

    try {
      let msgKey;
      const messageInterpolation = {};
      const visit = await store.dispatch('LOAD_VISIT', value);

      if (visit.failure) {
        msgKey =
          {
            forbidden: 'error.VALIDATE_UCRN.403',
            notFound: 'error.VALIDATE_UCRN.404',
            unassigned: 'error.VALIDATE_UCRN.unassigned',
            gone: 'error.VALIDATE_UCRN.410',
          }[visit.failure] ?? null;
      }

      if (!msgKey && (!visit.berthVisits || visit.berthVisits.length < 1)) {
        msgKey = 'error.VALIDATE_UCRN.noBerthVisits';
      }

      if (!msgKey && vesselCategory && visit?.vessel?.category !== vesselCategory) {
        msgKey = 'error.VALIDATE_UCRN.vesselCategoryMismatch';
        Object.assign(messageInterpolation, { vesselCategory });
      }

      return msgKey ? translate(msgKey, messageInterpolation) : true;
    } catch {
      return translate('error.5xx.title');
    }
  };

  return (options = {}) => isValid(options);
};
